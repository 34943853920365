import React from 'react';
import useFetchWeather from 'components/hooks/useFetchWeather';
import useFetchLiftsInfo from 'components/hooks/useFetchLiftsInfo';
import useFetchAlert from 'components/hooks/useFetchAlert';

import Header from 'components/header/header';
import Lifts from 'components/lifts/lifts';
import Slider from 'components/slider/slider';
import Weather from 'components/weather/weather';
import Footer from 'components/footer/footer';

import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

const App = () => {
  const { weatherData } = useFetchWeather();
  const { liftsInfo } = useFetchLiftsInfo();
  const { alert } = useFetchAlert();

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <div className={styles.background}>
          <Header weatherData={weatherData} />
          <Lifts liftsInfo={liftsInfo} />
        </div>
        <Slider />
        <Weather weatherData={weatherData} alert={alert} />
        <Footer />
      </div>
    </>
  );
};

export default App;
