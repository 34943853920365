/* eslint-disable react/no-array-index-key */
import React from 'react';
import { getIcon } from '../../helpers/weather';
import Alert from '../../assets/alert_danger.svg';
import styles from './weather.module.scss';

const WeatherBox = ({ weatherData, alert }) => {
  const date = new Date();

  return (
    <div className={styles.wrapper}>
      <div className={styles.weather}>
        <h2 className={styles.weather__name}>Aktualna pogoda</h2>
        <div className={styles.weather__box}>
          <div className={styles.weather__actual}>
            <img
              className={styles.weather__icon}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/dark/${
                date.getHours() < Number(weatherData?.sunset?.split(':')[0]) &&
                date.getHours() > Number(weatherData?.sunrise?.split(':')[0])
                  ? 'day'
                  : 'night'
              }/${getIcon(weatherData.weatherCode)}`}
              alt="Ikona pogody"
            />
            <p className={styles.weather__temperature}>{weatherData.temperature}&deg;C</p>
          </div>
          <ul className={styles.weather__column}>
            <li className={styles.weather__paragraph}>
              <img
                className={styles.weather__iconSmall}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/temperature.svg`}
                alt="Point icon"
              />
              <span className={styles.weather__text}>
                Temperatura odczuwalna:&nbsp;
                <span>{weatherData.perceivedTemperature}&deg;C</span>
              </span>
            </li>
            <li className={styles.weather__paragraph}>
              <img
                className={styles.weather__iconSmall}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/wind.svg`}
                alt="Point icon"
              />
              <span className={styles.weather__text}>
                Wiatr:&nbsp;
                <span>
                  {weatherData.windDirection}&nbsp;
                  {weatherData.windStrength}&nbsp;km/h
                </span>
              </span>
            </li>
            <li className={styles.weather__paragraph}>
              <img
                className={styles.weather__iconSmall}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/sunrise.svg`}
                alt="Point icon"
              />
              <span className={styles.weather__text}>
                Wsch. słońca:&nbsp;
                <span>{weatherData.sunrise}</span>
              </span>
            </li>
          </ul>
          <ul className={styles.weather__column}>
            <li className={styles.weather__paragraph}>
              <img
                className={styles.weather__iconSmall}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/humidity.svg`}
                alt="Point icon"
              />
              <span className={styles.weather__text}>
                Wilgotność:&nbsp;<span>{weatherData.humidity}&nbsp;%</span>
              </span>
            </li>
            <li className={styles.weather__paragraph}>
              <img
                className={styles.weather__iconSmall}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/pressure.svg`}
                alt="Point icon"
              />
              <span className={styles.weather__text}>
                Cieśnienie:&nbsp;<span>{weatherData.atmosphericPressure} hPa</span>
              </span>
            </li>
            <li className={styles.weather__paragraph}>
              <img
                className={styles.weather__iconSmall}
                src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/sunset.svg`}
                alt="Point icon"
              />
              <span className={styles.weather__text}>
                Zach. słońca:&nbsp;<span>{weatherData.sunset}</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      {alert.length > 0 &&
        alert.map((el, index) => (
          <div className={el.type === 'WARNING' ? styles.alertWarning : styles.alert} key={index}>
            <img className={styles.alert__icon} src={Alert} alt="Alert icon" />
            <p className={styles.alert__title}>{el.title}</p>
            <p className={styles.alert__text}>{el.text}</p>
          </div>
        ))}
    </div>
  );
};

export default WeatherBox;
