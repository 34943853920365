import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html {
  font-size: 0.52vh;
  font-family: 'Lato', sans-serif;
    ::-webkit-scrollbar {
      display: none;
    }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  line-height: 2.6rem;
  color: #242424;
  background-color: #FFFFFF;
}
`;

export default GlobalStyle;
