import React from 'react';

import styles from './weatherDetails.module.scss';

const WeatherDetails = ({ weatherData }) => (
  <div className={styles.wrapper}>
    <ul className={styles.weather}>
      <li className={styles.weather__item}>
        <img
          className={styles.weather__icon}
          src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/wind.svg`}
          alt="Point icon"
        />
        Wiatr:&nbsp;
        <span className={styles.weather__bold}>
          {weatherData.windDirection}&nbsp;
          <span className={styles.weather__big}>{weatherData.windStrength}</span>&nbsp;
          <span className={styles.weather__color}>km/h</span>
        </span>
      </li>
      <li className={styles.weather__border} />
      <li className={styles.weather__item}>
        <img
          className={styles.weather__icon}
          src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/humidity.svg`}
          alt="Point icon"
        />
        Wilgotność:&nbsp;
        <span className={styles.weather__bold}>
          <span className={styles.weather__big}>{weatherData.humidity}</span>&nbsp;
          <span className={styles.weather__color}>%</span>
        </span>
      </li>
      <li className={styles.weather__border} />
      <li className={styles.weather__item}>
        <img
          className={styles.weather__icon}
          src={`${process.env.PUBLIC_URL}/assets/img/weather/navy/pressure.svg`}
          alt="Point icon"
        />
        Cieśnienie:&nbsp;
        <span className={styles.weather__bold}>
          <span className={styles.weather__big}>{weatherData.atmosphericPressure}</span>&nbsp;
          <span className={styles.weather__color}>hPa </span>
        </span>
      </li>
    </ul>
  </div>
);

export default WeatherDetails;
